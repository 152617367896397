import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
//import PrivateRoute from 'react-private-route'
import { PrivateRoute } from "./components/PrivateRoute";
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { isAuthenticated } from "./components/Auth";
import "./style.css";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));

class App extends Component {



  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <PrivateRoute path="/" name="Home" component={props => <DefaultLayout {...props} />} isAuthenticated={!!isAuthenticated() /* this method returns true or false */} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
